import React from "react";
import "./myFavorite.css";

const Index = () => {
  return (
    <div>
      <h2>My Favorites</h2>
    </div>
  );
};

export default Index;
