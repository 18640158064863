import React, { useState, useContext } from "react";
import "./Register.css";

import UserImg from "./LoginImg";
import Footer from "../Footer/index";
import Logo from "../../Assets/_sponsorwave.png";
import { FcGoogle } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { useGoogleLogin } from "@react-oauth/google";
import { onLogin, onAuthWithGoogle } from "../../API/leApi";
import Loader from "../../utils/Loader";
import { useAuth } from "../../Context/AuthProvider";
import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPwd, setShowPwd] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [gLoginLoading, setGLoginLoading] = useState(false);
  const [serverErrMsg, setServerErrMsg] = useState("");

  const [emptyFields, setEmptyFields] = useState("");
  const [isError, setIsError] = useState("");
  const [logInData, setLogInData] = useState({
    email: "",
    pwd: "",
  });

  const { dispatch, setVerificationEmail } = useAuth();
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setCloseNotice } = contextValues as iGlobalValues;

  const login = useGoogleLogin({
    onSuccess: (tokenResponse: any) => handleCredentialResponse(tokenResponse),
    ux_mode: "popup",
    select_account: false,
    scope: "profile email openid",
    flow: "auth-code",
  }) as any;

  const handleCredentialResponse = async (response: any) => {
    setGLoginLoading(true);

    try {
      const gVerifyCode = response.code; // Access the ID token directly from the response object
      const res = await onAuthWithGoogle(gVerifyCode);

      if (res?.status === 201 || res?.status === 202) {
        let { customer_id, accessToken, user_id } = res.data?.user;

        // If they already bought give them access, otherwise show them the plans
        if (customer_id) {
          dispatch({
            type: "LOGIN",
            payload: {
              accessToken,
              user_id,
              customer_id,
            },
          });
          navigate(`/dashboard/${customer_id}`, { replace: true });
        } else {
          setCloseNotice(true);
          navigate("/chose-plan", { replace: true });
        }
      }
    } catch (error: any) {
      if (error.response) {
        alert(error?.response?.data?.error);
        dispatch({ type: "LOGOUT" });
      }
    } finally {
      setGLoginLoading(false);
    }
  };

  const handleLogin = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    if (!logInData.email || !logInData.pwd) {
      setEmptyFields("Please fill in all fields");
      return;
    }

    try {
      setIsLoading(true);
      const response = await onLogin(logInData);

      // If a user email is verified and logged in is successful
      if (response.status === 202) {
        // Save the user id and username in the context
        const { user_id, customer_id } = response?.data.user;
        setLogInData({ email: "", pwd: "" });
        console.log(customer_id);

        if (customer_id) {
          dispatch({
            type: "LOGIN",
            payload: {
              accessToken: response.data.token,
              user_id: user_id,
              customer_id: customer_id,
            },
          });
          // setRefreshCreatorData(true);
          navigate(`/dashboard/${customer_id}`, { replace: true });
        } else {
          navigate("/chose-plan", { replace: true });
        }
      }
    } catch (error: any) {
      if (error.response) {
        // Check if the error has a response and response data
        if (error.response.status === 403) {
          let theError = error.response.data.message;

          // If there are errors, update the state with the error message
          setServerErrMsg(theError);
          setVerificationEmail(logInData.email);
          navigate("/verify", { replace: true });
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors &&
          error.response.status === 500 &&
          error.response.data.errors.length > 0
        ) {
          setIsError(error.response.data.errors[0].msg);
        } else if (
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          setIsError(error.response.data.errors[0].msg);
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (gLoginLoading || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <section className='signSection'>
        <div className='login'>
          <img
            src={Logo}
            alt='logo'
            className='_auth_logo'
            onClick={() => navigate("/")}
          />
          <div>
            <div className='_login_text'>
              <h3 className='Title_h3'>Welcome back!</h3>
              <p className='loginTitle'>
                Don't have an account? what are you waiting for,{" "}
                <span onClick={() => navigate("/signup")}>Sign up</span>.
              </p>
            </div>
            <div className='_login_items_div'>
              <div className='Login_icon_div' onClick={() => login()}>
                <FcGoogle className='loginIcons' />
                <p>Login With Google</p>
              </div>
              <h3 className='or'>Or</h3>
              <form className='forms' onSubmit={(e) => handleLogin(e)}>
                <input
                  type='email'
                  className='forms_inputs'
                  placeholder='Email'
                  value={logInData.email}
                  onChange={(e) => {
                    setLogInData({ ...logInData, email: e.target.value });
                    setEmptyFields("");
                    setIsError("");
                  }}
                />

                <div>
                  <span
                    className='forms_inputs'
                    style={{ outline: isFocused ? "1px solid #1547d2" : "" }}>
                    <input
                      className='pwd_forms_input'
                      type={showPwd ? "text" : "password"}
                      placeholder='Password'
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      value={logInData.pwd}
                      onChange={(e) => {
                        setLogInData({ ...logInData, pwd: e.target.value });
                        setEmptyFields("");
                        setIsError("");
                      }}
                      autoComplete='on'
                    />
                    <span
                      className='pwdEye'
                      onClick={() => {
                        setShowPwd(!showPwd);
                      }}>
                      {showPwd ? <FaEye /> : <FaEyeSlash />}
                    </span>
                  </span>
                  <p className='forgetPwdLink'>Forget password?</p>
                </div>

                {/* Password error */}
                {emptyFields && <p id='pwdErrMsg'>{emptyFields}</p>}

                {/* Server error */}
                {isError && <p className='serverErrMsg'>{isError}</p>}
                {serverErrMsg && <p className='serverErrMsg'>{serverErrMsg}</p>}

                <button type='submit'>Login</button>
              </form>
            </div>
          </div>
        </div>
        <UserImg />
      </section>
      <Footer />
    </>
  );
};

export default Login;
