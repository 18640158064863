import React, { useEffect, useState, useContext } from "react";
import "./sponsors.css";

import { iSponsors, iSponsorStuff } from "../../Types/generalDataTypes";
import { onGetSponsorStuff } from "../../API/leApi";

import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { FaMoneyBills } from "react-icons/fa6";
import Web from "../../Assets/world-wide-web.png";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iChannels_info } from "../../Types/creatorStuffTypes";
interface iSponsorDetail {
  selectedSponsor: iSponsors;
  onBack: () => void;
}

const SponsorDetails = ({ selectedSponsor, onBack }: iSponsorDetail) => {
  const [sponsorStuff, setSponsorStuff] = useState<iSponsorStuff[] | null>();
  const [sponsoredChannels, setSponsoredChannels] = useState<
    iChannels_info[] | null
  >();

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setActiveComponent, setSelectedChannel } =
    contextValues as iGlobalValues;

  const handleSponsorStuff = async () => {
    try {
      let res = await onGetSponsorStuff(selectedSponsor.sponsor_id);

      if (res.status === 200) {
        setSponsorStuff(res.data.sponsor_stuff);
        setSponsoredChannels(res.data.sponsored_channels);
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    }
  };

  useEffect(() => {
    handleSponsorStuff();
  }, [selectedSponsor.sponsor_id]);

  const uniqueChannels = sponsoredChannels?.filter(
    (channel: iChannels_info, index: number, self: any) =>
      index ===
      self.findIndex((c: iChannels_info) => c.channel_id === channel.channel_id)
  );

  return (
    <section>
      <button onClick={onBack} className='_back_btn'>
        <MdOutlineKeyboardBackspace />
        Back to List
      </button>
      <div className='_creator_details'>
        <div className='_details_text'>
          <div className='_img_text_details'>
            <img
              src={selectedSponsor.sponsor_logo}
              alt={selectedSponsor.sponsor_name}
              className='_channel_img'
            />
            <span className='_details_name'>
              <h3>{selectedSponsor.sponsor_name}</h3>
              <p>{selectedSponsor.sponsor_industry}</p>
            </span>
          </div>
          <p className='_d_subs_spon_vids'>
            sponsored videos: &#20;
            <FaMoneyBills />
            {selectedSponsor.num_of_sponsored_videos}
          </p>
        </div>
        <div className='_channel_link'>
          Sponsor site:
          <a
            href={`${selectedSponsor.sponsor_website}`}
            rel='noreferrer'
            target='_blank'>
            <img src={Web} alt='' className='_sponsor_site_icon' />
          </a>
        </div>
      </div>
      <div className='_video_sponsors_sec'>
        <div className='_has_worked_with_container'>
          <h4 className='_sVideos_title'>Sponsored Channels:</h4>
          {uniqueChannels?.map((x) => (
            <div
              key={x.channel_id}
              onClick={() => {
                setSelectedChannel(x);
                setActiveComponent(2);
              }}>
              <img src={x.channel_photo} alt='' />
              <span className='_names_container'>
                <p>{x.channel_name}</p>
                <p className='_channel_name_sponsor'>{x.channel_username}</p>
              </span>
            </div>
          ))}
        </div>
        <div className='_sponsored_videos_container'>
          <h4 className='_sVideos_title'>Sponsored Videos:</h4>
          <div className='sV_container'>
            {sponsorStuff?.map((vid) =>
              selectedSponsor.sponsor_logo ? (
                <div className='_sponsored_video_container' key={vid.video_id}>
                  <div className='_sponsored_video_info'>
                    <div className='_sponsored_video_text'>
                      <p>Sponsored channel: </p>
                      <span className='_sponsor_img_info_span'>
                        <img
                          src={vid.channel_photo}
                          alt=''
                          className='_sponsored_sponsor_img'
                        />
                        <p>{vid.channel_name}</p>
                      </span>
                    </div>
                    <p className='_date_de_sponsor'>
                      {new Date(Number(vid.published_at)).toLocaleDateString(
                        "en-US",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </div>
                  <div className='_sponsored_video_img_desc'>
                    <div>
                      <a
                        href={`https://www.youtube.com/watch?v=${vid.video_id}`}
                        target='_blank'
                        rel='noreferrer'>
                        <img
                          src={vid.video_thumbnail}
                          alt='video_thumbnail'
                          className='_sponsored_video_thumbnail'
                        />
                      </a>
                    </div>
                    <div className='_title_and_desc'>
                      <h6 className='_sVideo_title'>{vid.video_title}</h6>
                      <p className='_sVideo_desc'>
                        {vid.video_description.length > 200
                          ? vid.video_description.substring(0, 200) + "..."
                          : vid.video_description}
                      </p>
                    </div>
                  </div>
                </div>
              ) : null
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SponsorDetails;
