import * as React from "react";
import "./Register.css";

const SVGComponent = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    className='loginImg'
    viewBox='0 0 851.23 844.66'>
    <defs>
      <style>
        {
          ".cls-1{isolation:isolate;}.cls-2{fill:#f3f3f8;}.cls-3{fill:#deddf2;}.cls-4,.cls-6{fill:none;stroke-miterlimit:10;}.cls-4{stroke:#007b88;stroke-linecap:round;stroke-width:1.33px;}.cls-5{fill:#ceceeb;}.cls-6{stroke:#000;stroke-width:1.85px;}.cls-7{fill:#fff;}.cls-8{fill:#c2e6ff;}.cls-9{fill:#0944f4;}.cls-10{mix-blend-mode:soft-light;}.cls-11{fill:#141018;}.cls-12{fill:#001bd2;}.cls-13{fill:#001ebb;}.cls-14{fill:#a9a7e4;}.cls-15{fill:#c5bcf8;}.cls-16{fill:#ecd5b8;}.cls-17{fill:#8109e5;}.cls-18{fill:#6a03d2;}.cls-19{fill:#e4caae;}.cls-20{fill:#ff5b35;}.cls-21{fill:#ff4321;}.cls-22{fill:#dde5e4;}.cls-23{fill:#c0d8d5;}.cls-24{fill:#19161e;}.cls-25{fill:#e3cbb0;}.cls-26{fill:#0e0b12;}.cls-27{fill:#0c070f;}"
        }
      </style>
    </defs>
    <g className='cls-1'>
      <g id='Layer_1' data-name='Layer 1'>
        <path
          className='cls-2'
          d='M1312.78,760.14c-3.61-9-7.46-17.82-11.41-26.64q-6.49-13.95-12.38-28.08c-10.17-24.44-18-49.58-24.42-75.09q-3.24-12.75-6.08-25.62c-1.63-10.22-3.21-20.72-4.67-31.54a283.16,283.16,0,0,0,1.59-35.81,275.27,275.27,0,0,0-2.93-35.18,233.62,233.62,0,0,0-2.23-23.47c1.32-40.4-.34-86.82-16.6-123.37a121.36,121.36,0,0,0-11.59-20.62c-24.9-31.07-63.55-53.15-102.19-42.11-50.72,12.08-97.22,38.95-149.3,46.29a188.48,188.48,0,0,1-22.72,1.85c-10.16-1.22-18.44,1.55-29.49-1.22-16.1-1.38-33.2-5.21-50.83-10.13-8.82-2.46-17.78-5.2-26.83-8-49.31-14.95-96.24-34.27-148.68-28.75-58,8.28-91.09,66.25-96,117.63-1.68,9.21-3.16,18.48-4.51,27.78-8.1,55.79-11.11,112.86-23.12,167.23-2,9.1-4.21,18.14-6.7,27.14-2.27,8.58-4.37,17.46-6.2,26.52-16.47,81.54-11.73,178.42,72.8,217.56A216.12,216.12,0,0,0,658,889.34c30.92,10.77,64.11,15.25,94.41,12.5,6.89,0,14.35,2.29,21.94,5.44s15.33,7.17,22.76,10.6q12.24,4.75,24.71,8.77c58.18,18.78,119.8,26.17,181,28.84,24.57,1.08,49.36,1.4,73.72-1.52,8.15-1,16.25-2.31,24.32-4.1,25.42-9.5,53.43-13,80.29-18.47,8.95-1.81,17.78-3.83,26.34-6.36,53.47-15.55,89.59-60.64,102.49-112a180.84,180.84,0,0,0,5-31.43A152.17,152.17,0,0,0,1312.78,760.14Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M1283.68,790.84c19.4-23.37,32.8-50.55,25-81.62-3.23-12.83-6.36-23.47,1.62-35.24,3.78-5.55,8.48-10.57,11.12-16.75,7.68-17.94.07-35.9-8.38-52-6.23-11.86-12.83-23.83-15.79-37-3.12-13.93-.18-28.13-1.85-42.19-1.62-13.63-9.56-28.33-23.09-30.63-9-1.53-18.08,2.95-24.68,9.22a49.13,49.13,0,0,0-14.5,27.23c-2.38,14.11,1.4,29.31-4.23,42.46-5,11.6-16.26,19.12-23.57,29.41a49.85,49.85,0,0,0-8.14,38.25c2.21,10.95,8.1,20.94,9.86,32,4,24.8-13.52,49.15-10.2,74,1.94,14.46,11,27.42,22.88,35.9,13.47,9.63,32.92,16.44,49.52,13.3a33.25,33.25,0,0,0,21.94-14.47'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M1255.36,780.3c1.24-5.94,1.32-11.58-.06-15.87-4.66-14.53-18.6-24.36-28.45-35.19-11.73-12.89-20.29-29.45-20.41-46.87-.09-14.14-3.2-26-13.07-36.79-9.69-10.57-21.93-18.35-32.13-28.37a222.26,222.26,0,0,1-28.83-34.28c-7.12-10.53-20.48-26.29-30.8-8.81a32.51,32.51,0,0,0-3.7,23.25c4.56,20.63,23.48,34.12,31.5,53.14,4.06,9.61,4.57,19.9,5.64,30.15,1,9.21,2.68,19.49,9.27,26.48,3.83,4.06,8.71,7.09,12.18,11.47,4.84,6.12,6.23,13.91,7.91,21.31,1.81,8,4.78,15.06,8.19,22.5,8.49,18.52,21,40.05,40.48,48.41,5.68,2.45,12.09,3.77,18.07,2.19C1244.49,809.5,1252.76,792.75,1255.36,780.3Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-4'
          d='M1149.89,686.33'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1225.67,622.57a1.83,1.83,0,0,1,2.55.49l29.07,43,6.48-111.92a1.88,1.88,0,0,1,1.94-1.73,1.84,1.84,0,0,1,1.73,1.94l-4.73,81.73,35.84-19.71a1.84,1.84,0,1,1,1.77,3.22l-37.49,20.62a1.45,1.45,0,0,1-.37.11l-4.36,75.45L1287,701.17a1.84,1.84,0,0,1,1.66,3.28L1257.85,720l-3.53,61.14a1.84,1.84,0,0,1-1.73,1.73h-.21a1.84,1.84,0,0,1-1.73-1.95l6.29-108.82-31.76-47A1.83,1.83,0,0,1,1225.67,622.57Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1332.1,651.22c5.94-15.83,9.48-27.43,11.25-33.69-2.69-13.66-7.07-49.34,19.24-54.65,14.24,7.5,18.6,34.33-12.17,56.13-2,6.94-6.12,20.63-13.41,39.54,21.91-32.56,50.3-29,58.93-14.25-5,32.05-52.91,24.42-62.23,22.63-4.13,10.24-9.07,21.74-15,34.3,23-23.49,47.53-17.43,54.39-3.16-7.1,29.44-48.65,21.37-61.58,18.09-5.7,11.5-12.12,23.73-19.34,36.56,29.87-28.06,59.43-18.79,66.46-1-11.1,34.83-61.19,20.51-75,15.81A884.23,884.23,0,0,1,1198.81,887l-5.51-4.64a878.75,878.75,0,0,0,84.81-119.57c-15.78-9.33-52.2-34.9-35.35-64.12,17.79-6.75,44.32,8.59,44.06,48.94,6.4-11.42,12.15-22.35,17.31-32.69-12.25-7.2-41.19-27.34-27.91-50.36,13.71-5.21,34,6.36,34.5,36.82,6.81-14.37,12.4-27.38,16.94-38.75-10.5-5.86-44.16-27.09-30-51.58C1311.82,605.7,1333.1,618.28,1332.1,651.22Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1153.44,699.84a1.85,1.85,0,0,1,2.52-.66l32.9,19.26-31.09-67.52a1.84,1.84,0,1,1,3.34-1.54l22.73,49.37,2.42-41.79a1.87,1.87,0,0,1,1.94-1.73,1.84,1.84,0,0,1,1.73,1.95l-2.75,47.45a1.76,1.76,0,0,1-.26.81l29.66,64.41,6.22-25.08a1.84,1.84,0,0,1,3.57.89l-6.95,28.05a1.92,1.92,0,0,1-.62,1l5.65,12.25a1.84,1.84,0,0,1-.91,2.44,1.89,1.89,0,0,1-.66.17,1.83,1.83,0,0,1-1.77-1.07l-29.78-64.66a1.77,1.77,0,0,1-1-.24l-36.26-21.21A1.85,1.85,0,0,1,1153.44,699.84Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M1276.87,793.73c-.47-4.19-.15-8.11,1.09-11,4.2-9.75,14.5-15.62,22-22.46,9-8.14,16-19,17.25-31.09,1-9.78,4-17.81,11.49-24.6s16.41-11.23,24.14-17.49a155.44,155.44,0,0,0,22.24-21.81c5.63-6.82,15.93-16.84,21.91-4a22.62,22.62,0,0,1,1,16.34c-4.53,14-18.53,22-25.35,34.69-3.44,6.38-4.49,13.47-5.91,20.5-1.27,6.31-3.15,13.31-8.18,17.71-2.92,2.56-6.51,4.33-9.2,7.13-3.75,3.91-5.23,9.21-6.89,14.22a78.29,78.29,0,0,1-7.17,15c-7.11,12.25-17.21,26.32-31.25,30.81-4.09,1.32-8.62,1.8-12.66.31C1282.44,814.67,1277.84,802.52,1276.87,793.73Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-6'
          d='M1353.19,735.24'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1294.39,799.64l4.54-7.79a1.86,1.86,0,0,1-.25-.67l-2.94-19.88a1.83,1.83,0,0,1,1.55-2.09,1.8,1.8,0,0,1,2.09,1.55l2.4,16.2,23.9-41a1.82,1.82,0,0,1-.08-.52l1.25-33a1.84,1.84,0,1,1,3.68.14l-1,26.85,17.9-30.73a1.84,1.84,0,1,1,3.17,1.85l-25.12,43.14,22.44-10.39a1.83,1.83,0,1,1,1.54,3.33l-26.51,12.27a1.62,1.62,0,0,1-.6.16l-24.76,42.5a1.85,1.85,0,0,1-1.48.91,1.81,1.81,0,0,1-1-.25A1.84,1.84,0,0,1,1294.39,799.64Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1216.9,230.36h-14.59a38,38,0,0,1,38,38V524.67a38,38,0,0,1-38,38h14.59a38,38,0,0,0,38-38V268.35A38,38,0,0,0,1216.9,230.36Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1216.9,563.33h-14.59V562a37.35,37.35,0,0,0,37.3-37.31V268.35a37.35,37.35,0,0,0-37.3-37.31v-1.35h14.59a38.7,38.7,0,0,1,38.65,38.66V524.67A38.7,38.7,0,0,1,1216.9,563.33Zm-4.43-1.35h4.43a37.35,37.35,0,0,0,37.3-37.31V268.35A37.35,37.35,0,0,0,1216.9,231h-4.43A38.73,38.73,0,0,1,1241,268.35V524.67A38.73,38.73,0,0,1,1212.47,562Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-7'
          d='M790.79,229.68h411.43a38,38,0,0,1,38,38V524a38,38,0,0,1-38,38H790.79a38,38,0,0,1-38-38V267.67A38,38,0,0,1,790.79,229.68Zm0,288.88h411.41a26.52,26.52,0,0,0,26.49-26.5V267.67a26.52,26.52,0,0,0-26.49-26.5H790.81a26.53,26.53,0,0,0-26.5,26.5V492.06A26.53,26.53,0,0,0,790.81,518.56Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1202.22,562.65H790.79A38.7,38.7,0,0,1,752.15,524V267.67A38.7,38.7,0,0,1,790.79,229h411.43a38.71,38.71,0,0,1,38.65,38.67V524A38.71,38.71,0,0,1,1202.22,562.65ZM790.79,230.35a37.35,37.35,0,0,0-37.29,37.32V524a37.35,37.35,0,0,0,37.29,37.31h411.43a37.35,37.35,0,0,0,37.3-37.31V267.67a37.35,37.35,0,0,0-37.3-37.32Zm411.43,288.89H790.81a27.21,27.21,0,0,1-27.18-27.18V267.67a27.21,27.21,0,0,1,27.18-27.18h411.41a27.2,27.2,0,0,1,27.16,27.18V492.06A27.2,27.2,0,0,1,1202.22,519.24ZM790.81,241.84A25.85,25.85,0,0,0,765,267.67V492.06a25.85,25.85,0,0,0,25.82,25.82h411.41A25.84,25.84,0,0,0,1228,492.06V267.67a25.85,25.85,0,0,0-25.81-25.83Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-8'
          d='M1228.79,268.35V492.74a26.46,26.46,0,0,1-15.22,24,26.13,26.13,0,0,1-11.26,2.53H790.9a26.53,26.53,0,0,1-26.5-26.5V268.35a26.45,26.45,0,0,1,26.5-26.5h411.41a26.21,26.21,0,0,1,4.28.34A26.54,26.54,0,0,1,1228.79,268.35Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1202.31,519.92H790.9a27.21,27.21,0,0,1-27.18-27.18V268.35a27.2,27.2,0,0,1,27.18-27.18h411.41a27.18,27.18,0,0,1,27.16,27.18V492.74a27.29,27.29,0,0,1-15.61,24.59A26.75,26.75,0,0,1,1202.31,519.92ZM790.9,242.52a26.36,26.36,0,0,0-4.17.34,25.73,25.73,0,0,0-21.66,25.49V492.74a25.86,25.86,0,0,0,25.83,25.83h411.41a25.29,25.29,0,0,0,11-2.47,25.9,25.9,0,0,0,14.83-23.36V268.35a25.71,25.71,0,0,0-21.63-25.49,26.36,26.36,0,0,0-4.17-.34Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-9'
          d='M1228.79,268.35v5.31H764.4v-5.31a26.56,26.56,0,0,1,22.22-26.16h420A26.54,26.54,0,0,1,1228.79,268.35Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1229.47,274.34H763.72v-6a27.07,27.07,0,0,1,22.79-26.82H1206.7a27.06,27.06,0,0,1,22.77,26.82ZM765.07,273h463v-4.63a25.71,25.71,0,0,0-21.58-25.48H786.68a25.73,25.73,0,0,0-21.61,25.48Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-7' cx={243.48} cy={146.33} r={6.04} />
        <path
          d='M791.37,264.64a6.71,6.71,0,1,1,6.71-6.71A6.72,6.72,0,0,1,791.37,264.64Zm0-12.07a5.36,5.36,0,1,0,5.36,5.36A5.36,5.36,0,0,0,791.37,252.57Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-7' cx={274.66} cy={146.33} r={6.04} />
        <path
          d='M822.55,264.64a6.71,6.71,0,1,1,6.71-6.71A6.72,6.72,0,0,1,822.55,264.64Zm0-12.07a5.36,5.36,0,1,0,5.36,5.36A5.36,5.36,0,0,0,822.55,252.57Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-7' cx={304.24} cy={146.33} r={6.04} />
        <path
          d='M852.14,264.64a6.71,6.71,0,1,1,6.71-6.71A6.73,6.73,0,0,1,852.14,264.64Zm0-12.07a5.36,5.36,0,1,0,5.36,5.36A5.36,5.36,0,0,0,852.14,252.57Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-9'
          d='M1050.11,341.76a45.24,45.24,0,1,1-45.24-45.24A45.14,45.14,0,0,1,1050.11,341.76Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1004.87,387.68A45.94,45.94,0,0,1,959,341.76a45.92,45.92,0,1,1,45.92,45.92Zm0-90.49a44.56,44.56,0,0,0-32.48,75.08,44.56,44.56,0,0,0,62.93,2,44.56,44.56,0,0,0-30.45-77.1Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-7' cx={455.53} cy={220.64} r={17.29} />
        <path
          d='M1003.42,350.21a18,18,0,1,1,18-18A18,18,0,0,1,1003.42,350.21Zm0-34.59A16.62,16.62,0,1,0,1020,332.24,16.64,16.64,0,0,0,1003.42,315.62Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-7'
          d='M1035.79,374.79a45.25,45.25,0,0,1-63.89-2.05,34.6,34.6,0,0,1,63.89,2.05Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1004.87,387.68a45.45,45.45,0,0,1-33.46-14.48l-.32-.33.19-.41a35.28,35.28,0,0,1,65.14,2.09l.16.42-.33.31A45.76,45.76,0,0,1,1004.87,387.68ZM972.71,372.6a44.56,44.56,0,0,0,62.28,2,33.92,33.92,0,0,0-62.28-2Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-7'
          x={316.54}
          y={284.96}
          width={278.82}
          height={30.7}
          rx={9.8}
        />
        <path
          d='M1133.46,427.94H874.23a10.49,10.49,0,0,1-10.48-10.48v-11.1a10.49,10.49,0,0,1,10.48-10.48h259.23a10.49,10.49,0,0,1,10.47,10.48v11.1A10.49,10.49,0,0,1,1133.46,427.94Zm-259.23-30.7a9.13,9.13,0,0,0-9.12,9.12v11.1a9.13,9.13,0,0,0,9.12,9.12h259.23a9.13,9.13,0,0,0,9.12-9.12v-11.1a9.13,9.13,0,0,0-9.12-9.12Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-7'
          x={316.54}
          y={328.19}
          width={278.82}
          height={30.7}
          rx={9.8}
        />
        <path
          d='M1133.46,471.17H874.23a10.49,10.49,0,0,1-10.48-10.47V449.59a10.48,10.48,0,0,1,10.48-10.47h259.23a10.48,10.48,0,0,1,10.47,10.47V460.7A10.49,10.49,0,0,1,1133.46,471.17Zm-259.23-30.7a9.13,9.13,0,0,0-9.12,9.12V460.7a9.13,9.13,0,0,0,9.12,9.12h259.23a9.13,9.13,0,0,0,9.12-9.12V449.59a9.13,9.13,0,0,0-9.12-9.12Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-9'
          d='M942.92,396.56v30.7H874.23a9.83,9.83,0,0,1-9.8-9.8v-11.1a9.83,9.83,0,0,1,9.8-9.8Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M943.6,427.94H874.23a10.49,10.49,0,0,1-10.48-10.48v-11.1a10.49,10.49,0,0,1,10.48-10.48H943.6Zm-69.37-30.7a9.13,9.13,0,0,0-9.12,9.12v11.1a9.13,9.13,0,0,0,9.12,9.12h68V397.24Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-9'
          d='M874.23,439.79h68.69v30.7H874.23a9.82,9.82,0,0,1-9.8-9.79V449.59A9.83,9.83,0,0,1,874.23,439.79Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M943.6,471.17H874.23a10.49,10.49,0,0,1-10.48-10.47V449.59a10.48,10.48,0,0,1,10.48-10.47H943.6Zm-69.37-30.7a9.13,9.13,0,0,0-9.12,9.12V460.7a9.13,9.13,0,0,0,9.12,9.12h68V440.47Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-8'
          x={406.31}
          y={293.1}
          width={169.17}
          height={15.66}
          rx={7.83}
        />
        <path
          d='M974,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M983.7,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.33-1.27-2.33-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61L985,450.4l1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M993.44,453.44l2.33,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.35,1.41-1-1.75,2.33-1.27L987,452.15l1-1.75,2.35,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1003.17,453.44l2.33,1.27-1,1.75-2.35-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.33-1.27-2.33-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.35-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1012.9,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1022.63,453.44l2.34,1.27-1.05,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1.05,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1032.36,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1042.09,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1051.82,453.44l2.34,1.27-1.05,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.33-1.27-2.33-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1.05,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1061.56,453.44l2.33,1.27-1.05,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.35,1.41-1-1.75,2.33-1.27-2.33-1.29,1-1.75,2.35,1.41,0-2.61h2l0,2.61,2.36-1.41,1.05,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1071.29,453.44l2.33,1.27-1,1.75-2.35-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.33-1.27-2.33-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.35-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1081,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1090.75,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1100.48,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          d='M1110.21,453.44l2.34,1.27-1,1.75-2.36-1.39,0,2.59h-2l0-2.61-2.36,1.41-1-1.75,2.34-1.27-2.34-1.29,1-1.75,2.36,1.41,0-2.61h2l0,2.61,2.36-1.41,1,1.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <g className='cls-10'>
          <polygon
            className='cls-7'
            points='412.96 407.64 333.75 407.64 244.41 130.25 323.62 130.25 412.96 407.64'
          />
          <polygon
            className='cls-7'
            points='458.04 407.64 434.39 407.64 345.05 130.25 368.68 130.25 458.04 407.64'
          />
          <polygon
            className='cls-7'
            points='645.08 407.64 565.86 407.64 476.51 130.25 555.73 130.25 645.08 407.64'
          />
          <path
            className='cls-7'
            d='M1228.79,490.58v2.16a26.5,26.5,0,0,1-15.22,24l-88.52-274.87h23.63Z'
            transform='translate(-547.89 -111.6)'
          />
        </g>
        <path
          className='cls-11'
          d='M1107.56,684.76H890.29V570.63a108.64,108.64,0,0,1,217.27,0Zm-181-36.22h144.84V570.63a72.42,72.42,0,1,0-144.84,0Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-11'
          x={403.63}
          y={569.87}
          width={92.52}
          height={102.56}
        />
        <path
          className='cls-12'
          d='M1138.9,600.91H859a17,17,0,0,0-17,17V837.42a17,17,0,0,0,17,17H1138.9a17,17,0,0,0,17-17V617.88A17,17,0,0,0,1138.9,600.91ZM986.12,725.51V706.75a12.81,12.81,0,0,1,25.61,0v18.76a25.9,25.9,0,1,1-25.61,0Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-13'
          d='M1155.88,617.88V837.42a17,17,0,0,1-17,17H859a17,17,0,0,1-17-17V826.51c66.55.92,165.62-2,219.26-23.75,76.82-31.17,76.74-160.77,74-201.85h3.57A17,17,0,0,1,1155.88,617.88Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-14'
          x={151.16}
          y={564.33}
          width={10.24}
          height={86.39}
        />
        <rect
          className='cls-14'
          x={691.57}
          y={771.99}
          width={25.09}
          height={3.28}
          transform='translate(-631.02 1350.89) rotate(-88.88)'
        />
        <circle className='cls-14' cx={155.87} cy={678} r={5.07} />
        <path
          className='cls-14'
          d='M729.46,789.6a5.07,5.07,0,1,1-5.06-5.06A5.06,5.06,0,0,1,729.46,789.6Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-14'
          points='178.35 675.15 175.07 675.15 175.07 668.19 156.85 649.97 159.16 647.65 178.35 666.83 178.35 675.15'
        />
        <path
          className='cls-14'
          d='M678.87,789.6a5.07,5.07,0,1,0,5.07-5.06A5.07,5.07,0,0,0,678.87,789.6Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-14'
          points='137.48 675.15 134.2 675.15 134.2 666.83 153.39 647.65 155.7 649.97 137.48 668.19 137.48 675.15'
        />
        <path
          className='cls-15'
          d='M747.77,548.71V678H653.61A20.49,20.49,0,0,1,633.1,657.5V548.71a36.57,36.57,0,0,1,36.57-36.57H711.2A36.57,36.57,0,0,1,747.77,548.71Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-14'
          d='M697.68,654.23V678H653.61A20.49,20.49,0,0,1,633.1,657.5v-3.27Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-15'
          x={120.44}
          y={542.63}
          width={108.11}
          height={23.75}
        />
        <path
          className='cls-16'
          d='M734.35,656.68c35.79,5.89,65.87,4.17,67.19-3.84s-26.62-19.28-62.41-25.17-65.87-4.17-67.19,3.84S698.57,650.79,734.35,656.68Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M795.12,650.75c25.25-11.9,34.46,98.55,28.86,122.53s-20.39-17-27.63-56S785.19,655.43,795.12,650.75Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-11'
          d='M823.67,774.77a64.41,64.41,0,0,0,10.79,10.14c7.42,5.75,9.39,4.54,9.54,6.66s-31,0-31,0l.63-17.41S823.67,774.31,823.67,774.77Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M766.2,644.77c35,11.11,65.44,13.83,67.89,6.1s-24-23-59-34.12-65.44-13.83-67.89-6.1S731.16,633.67,766.2,644.77Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M825.44,646.78c29.5,8.49-45.94,99.68-68,115.44s-1.61-22.09,20.67-59.54S813.83,643.44,825.44,646.78Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-11'
          d='M756.13,763.23s-.39,6.08,1.56,16.22,4.46,10.56,3,12.42-25.64-22.52-25.64-22.52l13.15-13.93S756.46,762.85,756.13,763.23Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-17'
          d='M691.84,611.93c2.14,15.35,26.29,26.87,50.15,35s47.66,12.8,48.47,14.42c1.57,3-36,74-40,81.43-.24.4-.35.63-.35.63L766,757.7S837.9,678,835.46,650.56c-2.2-25.54-96.37-46.16-96.37-46.16S687.61,581.18,691.84,611.93Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-18'
          d='M790.46,661.33c1.57,3-36,74-40,81.43C774.31,718.84,824.52,665,812,648c-16.27-22.06-80.67-31.1-80.67-31.1l10.71,30C766,655.08,789.65,659.71,790.46,661.33Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-17'
          d='M678.57,659.77c22.82,7.41,98.75,5.79,101.41,9s29,99.09,29,99.09h20.44c-.23,0-5.85-93.47-20.21-117.1S711,612.22,711,612.22L665,610.37s-.17.69-.35,1.91C663.17,620.56,658.65,653.28,678.57,659.77Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-18'
          d='M678.57,659.77c22.82,7.41,98.75,5.79,101.41,9s29,99.09,29,99.09h.52c.23-24.84-16.45-97.29-25-106.67-9.44-10.37-59.63-9.16-85.7-10.84-22.12-1.45-31.75-29.24-34.13-38C663.17,620.56,658.65,653.28,678.57,659.77Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-11'
          d='M726.11,520c4.51-6.94,8-15,12-22.15,2.63-4.72,5.34-9.81,4.84-15.19-.39-4.15-2.66-7.88-3.63-11.93a38.79,38.79,0,0,1-.63-10q.18-7,.34-13.93c.07-2.83.84-5.9-.77-8.08-2-2.77-6.07-5.07-8.7-7.26l-16.63-13.88-35,24.79,3.28,63.07L707,517.8l9.77,4.68c3.15,1.51,5.16,3.39,7.52.14C724.93,521.76,725.53,520.89,726.11,520Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M711.7,509.13c1.85,7.59,4.29,17.78,7.24,27.63,2.55,8.68,5.44,17,8.51,22.87s6.2,9.15,9.39,8.17a5.15,5.15,0,0,0,2.78-2.14c1.33-2.09,1.79-5.39,1.62-9.44a72,72,0,0,0-1.57-11.53c-.92-4.57-2.2-9.5-3.65-14.42-3.93-13.55-9-27.33-11.52-34.92-1.27-3.94-7.24-1.85-11.29-2.2C703.36,492.22,708.52,496,711.7,509.13Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M728.44,567.05c.75,2.78,35.38,14.76,59.71,20.61,13.38,3.31,23.63,4.7,23.45,1.74-.4-7-52.64-26.7-70.36-33.18-.64-.24-1.22-.47-1.74-.64a11.49,11.49,0,0,0-6.83-.35,9.26,9.26,0,0,0-3.25,1.68,5.57,5.57,0,0,0-2,2.72C726.64,562.07,727.8,564.73,728.44,567.05Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-19'
          d='M727.45,559.63c-.81,2.44.35,5.1,1,7.42.75,2.78,35.38,14.76,59.71,20.61-15.41-6.08-37.7-15.4-48.53-22-3.25-2-5.51-3.71-6.2-5a8,8,0,0,1-.75-5.39c.81-4.23,4.45-8.22,7-10.54-.92-4.57-2.2-9.5-3.65-14.42l-12.33-1-4.75,7.47C721.49,545.44,724.38,553.78,727.45,559.63Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M801.37,585.63c1.94-5.44,11-1,14,.58,4.92,2.64,8.67,9.44,7.66,11.41s-11.85-4.7-12.45-4.44,4.35,4,3.21,5.75-8.34-2.36-10.56-5.11S800.16,589,801.37,585.63Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M715.64,542l3.07-.35L734.34,540l6.14-.64s-2.49-48.47-18.76-46.21C706.72,495.23,715.64,542,715.64,542Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M715.58,536.06c-.06.58,1.27,2.84,3.13,5.56L734.34,540a80.76,80.76,0,0,0,1.86-12.39c1.33-18.48-7.13-28.84-7.13-28.84S715.81,534.27,715.58,536.06Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M665,610.37s52.18,7.93,74.07-6c0,0-1.21-2.61-2.78-6.83-1.21-3.25-2.72-7.53-4-12.28-2.2-7.82-4-17-3.88-25.48.41-20.33,6.37-23.52,7-36.66s-5.91-27.74-10.42-32c-9-8.51-31.34-8.63-40.49-4.23-8.1,3.94-11.35,9-12.39,14a162.73,162.73,0,0,0-2.72,18.65c-.81,7.93-1.33,16.73-1.74,25.65-.46,8.4-.69,17-.93,25-.11,4.06-.23,7.94-.34,11.64C666,594.21,665.66,604.52,665,610.37Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M667.57,545.21c9,1.33,21.43,2.32,23.28-1.21,3-5.68.46-32.32.46-32.32l-22,7.88C668.5,527.49,668,536.29,667.57,545.21Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M666.3,581.87c1.1,4.06,2.54,7.65,4.51,8.86,4.29,2.73,48.65,5.79,65.5,6.84-1.21-3.25-2.72-7.53-4-12.28-9.21-.64-56.87-4.46-56.87-4.46l-8.75-10.6C666.53,574.29,666.41,578.17,666.3,581.87Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M736.49,456.19c-.42,14.38-2.85,18.13-7.43,21.72-4.33,3.37-14.3-.11-15.4-.5a.09.09,0,0,0-.06,0l-.05-.05V489c0,.8,3.07,7.15-5.43,7.15s-17-6.73-17-6.73V468.94s-14.93-19.1-9-35,35.86-8.75,45.16-5.38S736.85,441.89,736.49,456.19Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-16'
          points='165.67 365.71 154.38 361.67 165.69 372.24 165.67 365.71'
        />
        <path
          className='cls-16'
          d='M671.16,585.17a2.92,2.92,0,0,0,1.56.47c2.61.11,4.87-2.09,6.84-5.85a63.91,63.91,0,0,0,3.42-9.73,199.39,199.39,0,0,0,4.22-24.73c.18-1.51.35-3.07.47-4.63,1.56-14.66,2.2-29.13,2.43-36.43.11-4.11-1.85-8.17-5.56-9.91-8.92-4.22-13.73,14.19-15.23,27.57-.47,4.23-1,9.33-1.57,14.71C665.72,556.85,664.44,581.7,671.16,585.17Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M671.16,585.17a.45.45,0,0,0,.12.24c2,3.59,67,8.22,89.76,5,5.27-.76,8.29-1.92,7.76-3.65-2.37-7.47-63.93-14.42-85.82-16.68-1.05-.12-2-.23-2.78-.29a12,12,0,0,0-9,2.55,9.46,9.46,0,0,0-1.68,1.73C666.3,578.34,669.25,581.93,671.16,585.17Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-19'
          d='M671.16,585.17a.45.45,0,0,0,.12.24c2,3.59,67,8.22,89.76,5a.5.5,0,0,0,0-.76c-3-3.47-62.43-6.89-81.48-9.84a20.9,20.9,0,0,1-5.5-1.28c-1.57-.87-2.44-3.18-2.84-6.19-1.16-8.05.81-21,1.33-24.85.64-4.75,12.51-2.6,14.65-2.14a1.56,1.56,0,0,0,.35.06l1.91-3.07-1.79-1.62-4.17-3.77-15.76-.29C665.72,556.85,664.44,581.7,671.16,585.17Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M756.54,585.47c.61-6.52,11.73-4.2,15.5-3.26,6.13,1.52,12.15,7.94,11.59,10.38s-14.31-1.85-14.91-1.4,5.9,3.18,5.13,5.42-9.82-.27-13-2.67S756.16,589.55,756.54,585.47Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M665.31,539.81s1.05-47.57,16.19-46.46c16.37,1.19,8,49,8,49Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-11'
          d='M738.94,448.78c1.94-11.09,2.6-18.2-.57-25-7.16-15.36-45.57-25.07-59,3.92q-1.39,3-2.62,6.1A190.77,190.77,0,0,0,665,481.57a146.23,146.23,0,0,0-1.22,20.81,68.44,68.44,0,0,0,.57,8.09c.38,2.73.34,6.75,2,8.89a14.74,14.74,0,0,0,4.21,3.29,8.4,8.4,0,0,0,4,1.56,7.92,7.92,0,0,0,4.12-1.47c5.52-3.32,10.61-7.59,14-13.08s5-12.27,3.37-18.52c-.49-1.91-1.26-3.74-1.82-5.62a27.22,27.22,0,0,1-.07-15c.68-2.43,2.31-4.59,2.8-7.07a27.73,27.73,0,0,0,.47-6.63,24.87,24.87,0,0,1-.13-3.34,14.85,14.85,0,0,1,.6-2.86l1.44-5.13c.52-1.83,1.09-3.76,2.5-5a11.93,11.93,0,0,1,3.32-1.83c4.87-2.18,8.3-5.59,9.67-10.74,6.68,7.94,13.16,18.46,24.11,20.9'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-22'
          d='M888.94,535.28l-30,61.4s-62.32-.46-62.78,0l30-61.4Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-23'
          points='203.17 484.91 248.43 484.88 250.23 481.19 252.28 477.01 204.36 476.68 203.75 480.81 203.17 484.91'
        />
        <polygon
          className='cls-23'
          points='278.26 423.68 275.95 423.68 246.52 484.62 248.42 484.87 278.26 423.68'
        />
        <path
          className='cls-23'
          d='M751.06,596.51l45.26,0,1.8-3.69c0,.15-46.56-.38-46.48-.38Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-23'
          d='M849.09,570c-2.49,3.09-6.38,4.08-8.69,2.22s-2.14-5.87.36-9,6.38-4.09,8.68-2.23S851.59,566.85,849.09,570Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-24'
          x={92}
          y={487.15}
          width={7.15}
          height={194.61}
        />
        <rect
          className='cls-24'
          x={339.7}
          y={487.15}
          width={7.15}
          height={194.61}
        />
        <rect
          className='cls-11'
          x={601.57}
          y={595.55}
          width={331.31}
          height={14.29}
          transform='translate(986.56 1093.8) rotate(180)'
        />
        <path
          className='cls-25'
          d='M1196,626.24h.39a35.26,35.26,0,0,0,10.82-3.82l0,.1v7.77s-1.5,7.39-10.58,9.47c-9-2.08-10.58-9.51-10.58-9.51V623.1l.1-.15A35.59,35.59,0,0,0,1196,626.24Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M1196.37,626.24H1196a35.59,35.59,0,0,1-9.8-3.29c-4.78-2.36-9.66-6.37-10.63-12.89,0,0-5.55-2.42-5.45-7s4.92-3.33,4.92-3.33-4-14.3,3.53-24.83,17.19-7.29,17.19-7.29h.78s9.7-3.28,17.24,7.29,3.52,24.83,3.52,24.83,4.83-1.21,4.93,3.33-5.41,7-5.41,7c-.92,6.08-5.22,10-9.61,12.36A35.26,35.26,0,0,1,1196.37,626.24Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-7'
          d='M1242.88,739.55l.1.29c-21.54,8.45-44.39,22.31-46.32,23.47-1.93-1.16-24.73-15-46.27-23.47l.1-.29V707.72h92.39v31.83Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M1150.48,719.32v20.23l-.11.29c-4-1.61-8.1-3-11.9-4.13-9.72-7.85-23-19.32-24.51-24.21-1.21-3.87,4-15.19,10.63-27.54l.11,0,16.47,9.27,5.26,3c-3,5.33-5.44,10.44-5,11.72,1,3,8.88,11.33,8.88,11.33Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-16'
          d='M1279.38,711.5c-1.46,4.86-14.72,16.33-24.44,24.18-3.8,1.13-7.85,2.52-12,4.16l-.07-.29V719.32h.15s7.81-8.33,8.87-11.33c.48-1.28-2-6.39-5-11.72l5.23-3,16.51-9.31.11,0C1275.4,696.31,1280.62,707.63,1279.38,711.5Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-25'
          d='M1259.4,709.2c-2.19,3.76-10.41,13.29-16.51,20.27V719.32h.15s7.81-8.33,8.87-11.33c.48-1.28-2-6.39-5-11.72l6.49-3.64C1256.45,696.68,1262,704.85,1259.4,709.2Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M1268.78,684l-.11,0-15.24,8.61-1.27.72,0,0-5.19,2.93c-.8-1.46-1.65-2.93-2.48-4.36-2.79-4.78-5.44-9-5.94-9.75a.08.08,0,0,1,0,0l0-.08s-1,16.92-.61,24.89v.78h-82.42v-.78c.52-8-.58-24.89-.58-24.89s0,0,0,.11c-.63.93-4.86,7.7-8.39,14.13l-5.27-3L1124.69,684l-.11,0c6.76-12.75,15.1-26.67,19.43-34.7,7-12.89,30.56-17.42,39-18.63,1.93-.28,3.07-.39,3.07-.39s1.51,7.45,10.54,9.53c9.08-2.08,10.6-9.47,10.6-9.47s1.13.11,3,.38c8.44,1.19,32,5.66,39,18.58,3.9,7.15,10.88,19,17.17,30.47C1267.29,681.14,1268,682.55,1268.78,684Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M1253.43,692.6l-1.27.72,0,0-5.19,2.93c-3.53-6.35-7.75-13.11-8.42-14.11a.08.08,0,0,1,0,0V670.84S1247.72,682.25,1253.43,692.6Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M1154.87,671.47V682s0,0,0,.11c-.62,1-4.86,7.71-8.4,14.14l-5.26-3A229.18,229.18,0,0,1,1154.87,671.47Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-25'
          d='M1150.48,719.32v7.19c-7.27-5.66-14.79-12.67-15-17.75-.11-3.58,2.41-9.46,5.66-15.49l5.26,3c-3,5.33-5.44,10.44-5,11.72,1,3,8.88,11.33,8.88,11.33Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-11'
          d='M1172.09,594.05a8.27,8.27,0,0,1-1.33-5,7.78,7.78,0,0,1,2.73-5.53,4.46,4.46,0,0,1-.23-2.82,3.33,3.33,0,0,1,2.34-2.44,7.93,7.93,0,0,1,.63-5.72,5,5,0,0,1,6.1-2.25,8,8,0,0,1,3.83-6.67,5.86,5.86,0,0,1,6.09.57,3.16,3.16,0,0,1,2.12-2,3.21,3.21,0,0,0,5.1,0,3.11,3.11,0,0,1,2.11,2,5.89,5.89,0,0,1,6.1-.57,8,8,0,0,1,3.83,6.67,5,5,0,0,1,6.1,2.25,7.9,7.9,0,0,1,.62,5.72,3.36,3.36,0,0,1,2.35,2.44,4.37,4.37,0,0,1-.24,2.82,7.77,7.77,0,0,1,2.74,5.53,8.27,8.27,0,0,1-1.33,5,3.15,3.15,0,0,1,.94,3.28,2.41,2.41,0,0,1-2.35,1.88,4.22,4.22,0,0,1-1,.84,3.15,3.15,0,0,1-.86.33,3.21,3.21,0,0,1-1.61-1.41,5.14,5.14,0,0,1-.15-4.08,8.38,8.38,0,0,1-2-6.14,6.63,6.63,0,0,1,.55-1.88,6.62,6.62,0,0,1-3.71-4.22,7.09,7.09,0,0,1-.24-2.35,1.92,1.92,0,0,1-2.34,0,2.51,2.51,0,0,1-.82-1.36,6.17,6.17,0,0,1-3.87.94,6.52,6.52,0,0,1-3.79-1.83s-.55,2.05-3.52,2.1h-.1c-3,0-3.52-2.1-3.52-2.1a6.55,6.55,0,0,1-3.79,1.83,6.15,6.15,0,0,1-3.87-.94,2.65,2.65,0,0,1-.82,1.36,1.93,1.93,0,0,1-2.35,0,7.32,7.32,0,0,1-.23,2.35,6.6,6.6,0,0,1-3.72,4.22,6.63,6.63,0,0,1,.55,1.88,8.44,8.44,0,0,1-1.95,6.14A5.16,5.16,0,0,1,1177,599a3.24,3.24,0,0,1-1.6,1.41,3,3,0,0,1-.86-.33,4.11,4.11,0,0,1-1.06-.84,2.41,2.41,0,0,1-2.34-1.88A3.13,3.13,0,0,1,1172.09,594.05Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-24'
          d='M1236.53,778.16l-.11,6.83-.14,9.49s-9.35-.84-22.87-2.59c-17.34-2.27-41.6-6.06-61.9-11.65-18.52-5.08-33.79-11.62-37.59-19.8-8.29-17.82,9.28-32.07,29.51-25.05,8.11,2.84,24.66,10.22,41.61,18.11C1210.35,765.3,1236.53,778.16,1236.53,778.16Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-26'
          d='M1236.53,778.16l-.11,6.83-23,6.9c-17.34-2.27-41.6-6.06-61.9-11.65,10-6.25,24.47-18.67,33.53-26.74C1210.35,765.3,1236.53,778.16,1236.53,778.16Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-24'
          d='M1243.38,781v14.41s19,1.26,20.23-.87,2.7-9.81-2.13-13.49S1244.89,777.17,1243.38,781Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-16'
          points='688.17 666.56 695.63 669.3 695.63 683.64 687.89 682.87 688.17 666.56'
        />
        <path
          className='cls-24'
          d='M1157.1,794.47s111.11-9.84,122.36-34c8.29-17.83-9.29-32.07-29.54-25s-93.1,42.76-93.1,42.76Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-16'
          points='609.2 666.56 601.74 669.3 601.74 683.64 609.48 682.87 609.2 666.56'
        />
        <path
          className='cls-24'
          d='M1149.77,781v14.41s-19,1.26-20.23-.87-2.7-9.81,2.13-13.49S1148.26,777.17,1149.77,781Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-3'
          x={146.86}
          y={130.67}
          width={45.41}
          height={45.41}
          rx={8.42}
        />
        <path
          className='cls-5'
          d='M748.15,305.39H716.94a9.08,9.08,0,0,1-9.07-9.07V265.11a9.08,9.08,0,0,1,9.07-9.06h31.21a9.07,9.07,0,0,1,9.06,9.06v31.21A9.07,9.07,0,0,1,748.15,305.39Zm-31.21-42.21a1.93,1.93,0,0,0-1.93,1.93v31.21a1.93,1.93,0,0,0,1.93,1.94h31.21a1.94,1.94,0,0,0,1.93-1.94V265.11a1.94,1.94,0,0,0-1.93-1.93Z'
          transform='translate(-547.89 -111.6)'
        />
        <rect
          className='cls-3'
          x={668.64}
          y={220.7}
          width={34.49}
          height={34.49}
          rx={6.39}
        />
        <path
          className='cls-5'
          d='M1234.18,380.24h-23.7a6.9,6.9,0,0,1-6.89-6.89v-23.7a6.89,6.89,0,0,1,6.89-6.88h23.7a6.89,6.89,0,0,1,6.88,6.88v23.7A6.89,6.89,0,0,1,1234.18,380.24Zm-23.7-32.06a1.47,1.47,0,0,0-1.47,1.47v23.7a1.47,1.47,0,0,0,1.47,1.47h23.7a1.46,1.46,0,0,0,1.46-1.47v-23.7a1.46,1.46,0,0,0-1.46-1.47Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1205.77,159.4a3.48,3.48,0,0,1-2.45-1l-8.25-8.25a3.49,3.49,0,0,1,0-4.9l8.25-8.25a3.47,3.47,0,0,1,4.9,0l8.25,8.25a3.48,3.48,0,0,1,0,4.9l-8.25,8.25A3.44,3.44,0,0,1,1205.77,159.4Zm0-20.61a.65.65,0,0,0-.46.19l-8.25,8.25a.66.66,0,0,0,0,.92l8.25,8.25a.55.55,0,0,0,.92,0l8.25-8.25a.65.65,0,0,0,0-.92l-8.25-8.25A.62.62,0,0,0,1205.77,138.79Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1246.38,497.57a3.49,3.49,0,0,1-2.45-1l-8.24-8.25a3.46,3.46,0,0,1,0-4.89l8.24-8.25a3.46,3.46,0,0,1,4.9,0l8.25,8.25a3.46,3.46,0,0,1,0,4.89l-8.25,8.25A3.46,3.46,0,0,1,1246.38,497.57Zm-.46-3a.55.55,0,0,0,.92,0l8.25-8.25a.62.62,0,0,0,.19-.46.64.64,0,0,0-.19-.46l-8.25-8.25a.55.55,0,0,0-.92,0l-8.25,8.25a.68.68,0,0,0-.19.46.67.67,0,0,0,.19.46Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M831.91,135a3.46,3.46,0,0,1-2.45-1l-8.25-8.25a3.45,3.45,0,0,1,0-4.9l8.25-8.25a3.46,3.46,0,0,1,4.89,0l8.25,8.25a3.45,3.45,0,0,1,0,4.9L834.35,134A3.45,3.45,0,0,1,831.91,135Zm0-20.61a.65.65,0,0,0-.46.19l-8.25,8.25a.64.64,0,0,0-.19.46.66.66,0,0,0,.18.46l8.26,8.25a.55.55,0,0,0,.92,0l8.25-8.25a.65.65,0,0,0,0-.92l-8.25-8.25A.68.68,0,0,0,831.91,114.41Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M633.4,480.51a3.41,3.41,0,0,1-2.44-1l-8.25-8.25a3.44,3.44,0,0,1,0-4.89l8.25-8.25a3.4,3.4,0,0,1,2.44-1h0a3.41,3.41,0,0,1,2.45,1l8.25,8.25a3.46,3.46,0,0,1,0,4.89l-8.25,8.25A3.42,3.42,0,0,1,633.4,480.51Zm0-20.61a.64.64,0,0,0-.45.19l-8.26,8.26a.64.64,0,0,0,0,.91l8.25,8.25a.64.64,0,0,0,.92,0l8.25-8.25a.64.64,0,0,0,0-.91l-8.25-8.25A.64.64,0,0,0,633.4,459.9Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M1342.32,539.74a3.65,3.65,0,0,1-2.61-1.08l-9.59-9.59a3.68,3.68,0,0,1,0-5.21l7.93-7.93a3.68,3.68,0,0,1,5.21,0l9.6,9.59a3.7,3.7,0,0,1,0,5.21l-7.94,7.93A3.65,3.65,0,0,1,1342.32,539.74Zm-.5-3.18a.71.71,0,0,0,1,0l7.94-7.94a.71.71,0,0,0,0-1l-9.6-9.59a.69.69,0,0,0-1,0l-7.93,7.93a.69.69,0,0,0,0,1Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-3' cx={248.56} cy={261.06} r={5.58} />
        <path
          className='cls-3'
          d='M1303.89,262.71a5.95,5.95,0,1,1-6-5.95A5.94,5.94,0,0,1,1303.89,262.71Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M1007.12,137.11a5.95,5.95,0,1,1-6-6A5.95,5.95,0,0,1,1007.12,137.11Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M871.86,291.69a6.87,6.87,0,1,1-6.86-6.87A6.86,6.86,0,0,1,871.86,291.69Z'
          transform='translate(-547.89 -111.6)'
        />
        <circle className='cls-3' cx={118.91} cy={250.02} r={4.97} />
        <path
          className='cls-3'
          d='M792.68,449a5,5,0,1,1-5-5A5,5,0,0,1,792.68,449Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M637.16,400.83a2.19,2.19,0,1,1-2.19-2.18A2.19,2.19,0,0,1,637.16,400.83Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M1141.36,154.81a53.2,53.2,0,0,0-18-3,54.53,54.53,0,0,0-52,39.67v0h-177l-72.71,0a11.16,11.16,0,0,0-11.15,11.13v18.63h0v16.34a9.36,9.36,0,0,0,9.38,9.36h1.66a9.38,9.38,0,0,0,9.39-9.36V221.31h11.81v16.34a9.38,9.38,0,0,0,9.39,9.36h2.15a9.37,9.37,0,0,0,9.38-9.36V221.31l11.9,0,0,15.82c0,4.91,3.5,9.38,8.39,9.85a9.35,9.35,0,0,0,10.35-9.33V221.28h177a54.61,54.61,0,0,0,52.5,39.67,54.58,54.58,0,0,0,54.52-57.14C1177.33,181.12,1161.93,161.91,1141.36,154.81Zm16.81,63.24a14,14,0,1,1,.08-23.27h0a14,14,0,0,1-.11,23.27Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-21'
          d='M1178.38,203.81a54.57,54.57,0,0,1-97.41,36.3c18,8.69,38.7,12.61,57.77,1.9,11.12-6.26,17-14.68,19.43-24a14,14,0,0,0,.11-23.27h0c-3.06-15.81-11.34-31.05-16.89-40C1161.93,161.91,1177.33,181.12,1178.38,203.81Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-19'
          d='M713.66,477.41a.09.09,0,0,0-.06,0l-.05-.05v6.27c-4.47-3.7-9.08-10.33-9.41-10.85l9.44,4.55Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M903.84,581.9c-.87-2-4.37-9.71-5.23-14.58s-3.51-8.84-3.58-11.27,3.92-3.87,5-8.87,3.14-13.16,4.44-12.91,2.18,9.2,3.42,11.41,4.57,5,2,8.14-1.21,7,1.16,10.31-4.34,7.46-3.68,12-.59,10.6-.59,10.6Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M905,572.25a.41.41,0,0,1-.39-.35,25.5,25.5,0,0,0-2-7.08,12.6,12.6,0,0,1-1.11-3.85,5.82,5.82,0,0,1,1.52-3.61,7.5,7.5,0,0,0,1.65-3.38c.37-2.84-2.19-9.13-2.22-9.2a.39.39,0,0,1,.22-.51.39.39,0,0,1,.51.22c.11.26,2.67,6.55,2.27,9.59a8.22,8.22,0,0,1-1.79,3.74,5.06,5.06,0,0,0-1.37,3.12,11.76,11.76,0,0,0,1.06,3.59,26.71,26.71,0,0,1,2,7.28.41.41,0,0,1-.35.44Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M897.18,580.4c-1-.43-1.28-5.71-3.06-6.5s-3-3.25-3.64-5.81-5.81-3.64-6.8-6.4,2-4.72,1.78-7.29-2.37-6.2-.79-7.87,4.33,4.13,5.81,5.31,4.33,2.47,3.54,4.63.3,5.71,5.42,7.68S907.91,585.23,897.18,580.4Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M898,571.34a.38.38,0,0,1-.37-.27c-1-3.08-2.59-4.29-4.42-5.7a19.19,19.19,0,0,1-2.88-2.53c-2.87-3.17-1.53-8.69-1.47-8.92a.38.38,0,0,1,.47-.29.39.39,0,0,1,.29.48s-1.29,5.35,1.29,8.2a20.19,20.19,0,0,0,2.77,2.44,11.29,11.29,0,0,1,4.7,6.08.4.4,0,0,1-.25.49A.32.32,0,0,1,898,571.34Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-3'
          d='M906.73,581c.26-.79.52-4.2,2.36-5s3.68-1.31,5.12-3.67,0-6,1.58-8.54,5.12-.13,7-2.62-2.23-6.83-.78-8.67,3.41-6.83,1.44-7.48-7.48,2.89-8.93,6-4.33,5-5,7.48-2.75,3.81-3.28,6.3.26,6.3-.66,9.06S906.73,581,906.73,581Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-20'
          d='M911.43,595.17H894.94a2.22,2.22,0,0,1-2.22-2.12l-.7-17.57h22.32l-.7,17.57A2.21,2.21,0,0,1,911.43,595.17Z'
          transform='translate(-547.89 -111.6)'
        />
        <path
          className='cls-5'
          d='M910.4,571.8h0a.39.39,0,0,1-.36-.42,21.76,21.76,0,0,1,5.34-13.49c1.89-2.26,3.06-6.63,3.07-6.67a.4.4,0,0,1,.77.2c-.05.19-1.23,4.58-3.23,7a20.92,20.92,0,0,0-5.16,13A.4.4,0,0,1,910.4,571.8Z'
          transform='translate(-547.89 -111.6)'
        />
        <polygon
          className='cls-22'
          points='242.48 454.82 241.96 464.35 241.38 474.88 240.9 483.81 223.66 483.81 223.2 474.88 222.65 464.35 222.15 454.82 242.48 454.82'
        />
        <rect
          className='cls-27'
          x={223.43}
          y={452.04}
          width={17.77}
          height={3.12}
        />
        <rect
          className='cls-27'
          x={220.99}
          y={454.71}
          width={22.66}
          height={4.03}
        />
        <polygon
          className='cls-20'
          points='241.96 464.35 241.38 474.88 223.2 474.88 222.65 464.35 241.96 464.35'
        />
        <circle className='cls-22' cx={232.32} cy={469.64} r={2.79} />
      </g>
    </g>
  </svg>
);
export default SVGComponent;
