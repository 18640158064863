import React from "react";
import "./how_it_works.css";

import SAASDashboard from "../../Assets/SaaS Dashboard.png";
import PWD from "../../Assets/PSW.png";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthProvider";

const Index = () => {
  let navigate = useNavigate();
  const { state } = useAuth();

  const handleAccess = () => {
    state.isAuthenticated && state.accessToken
      ? navigate(`/dashboard/${state.customer_id}`)
      : navigate("/signUp");
  };

  return (
    <section className='_how_it_works_section'>
      <div className='_how_it_works_text'>
        <h3 className='_how_it_works_title'>How it works...</h3>
        <p className='_how_it_works_p'>
          {/* How to find your sponsors with Sponsorwave? */}
          Sponsorwave is your ultimate tool to discover and connect with
          sponsors tailored to your YouTube channel. Follow these simple steps
          to find the perfect sponsors and maximize your revenue.
        </p>
      </div>
      <div className='_functionalities_container'>
        <div>
          <span>
            <h4>Search for Sponsors or YouTubers...</h4>
            <p>
              Look up a specific sponsor to see who they've collaborated with,
              or find a creator in your niche to discover their past
              sponsorships and whether they could be a good fit for your
              channel!
            </p>
          </span>

          <img src={SAASDashboard} alt='' className='_how_it_works_img' />
        </div>
        <div>
          <span className=''>
            <dt className='dt_name'>Have no one in mind? No problem...</dt>
            <h4>Meet Search+</h4>
            <p>
              Use our powerful Search+ tool to discover sponsors and creators
              tailored to your niche. Simply enter specific parameters, like
              industry or niche or past collaborations, and get a curated list
              of potential sponsors or creators that perfectly match your needs.
            </p>
          </span>

          <img src={PWD} alt='' className='_how_it_works_img2' />
        </div>
        <div>
          <span>
            <h4>Create Your Sponsors List...</h4>
            <p>
              Easily add sponsors or creators to your favorites list. Track
              brands you want to work with, organize prospects, and access them
              anytime to streamline your outreach and maximize opportunities.
            </p>
          </span>
          <img src={PWD} alt='' className='_how_it_works_img1' />
        </div>
      </div>
      <div className='_pitch_container'>
        <span className=''>
          <h4>Start Pitching...</h4>
          <p>
            Craft compelling pitches and begin reaching out to potential
            sponsors. Use the insights and data from Sponsorwave to create
            personalized outreach strategies that resonate with brands and
            increase your chances of landing sponsorships.
          </p>
        </span>
        <button className='_how_it_works_btn' onClick={() => handleAccess()}>
          Get Started Today!
        </button>
      </div>
    </section>
  );
};

export default Index;
