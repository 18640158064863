import React, { useState, useEffect } from "react";
import "./homeBody.css";

import { CgArrowDown, CgArrowUp } from "react-icons/cg";
import Sponsors from "../../Assets/sponsors.png";
import SponsoredVideos from "../../Assets/sponsor.png";
import Creators from "../../Assets/youtuber.png";
import LaTable from "../Table/LaTable";
import { onGetHomeStats } from "../../API/leApi";

import Ali from "../../Assets/Ali.jpg";
import Ruri from "../../Assets/ruri.jpg";
import Dave2d from "../../Assets/dev2d.jpg";
import Unnamed from "../../Assets/unnamed.jpg";
import Sara from "../../Assets/sara.jpg";

const Index = () => {
  const [getStats, setGetStats] = useState({
    sponsors: 0,
    sponsored_videos: 0,
    YouTubers: 0,
  });

  useEffect(() => {
    const handelHomeStats = async () => {
      try {
        const res = await onGetHomeStats();
        if (res.status === 200) setGetStats(res.data);
      } catch (error: any) {
        alert("Something went wrong in getting dashboard data, try again!");
      }
    };

    handelHomeStats();
  }, []);

  return (
    <>
      <div className='_hb_header'>
        <div className='_hb_ele_container'>
          <img src={Sponsors} alt='sponsors_icon' className='_hb_icon' />
          <div className='_text_holder'>
            <p className='_stats_title'>Sponsors</p>
            <h4 className='_stats_numbers'>{getStats.sponsors}</h4>
            <div className='_stats_holder'>
              <CgArrowUp className='_dataUp' />
              <p>
                <span className='_dataUp'>18%</span> This month
              </p>
            </div>
          </div>
        </div>
        <div className='_hb_ele_container'>
          <img src={SponsoredVideos} alt='' className='_hb_icon' />
          <div className='_text_holder'>
            <p className='_stats_title'>Sponsored Videos</p>
            <h4 className='_stats_numbers'>{getStats.sponsored_videos}</h4>
            <div className='_stats_holder'>
              <CgArrowDown className='_dataDown' />
              <p>
                <span className='_dataDown'>9.3% </span>This month
              </p>
            </div>
          </div>
        </div>
        <div className='_hb_ele_container'>
          <img src={Creators} alt='' className='_hb_icon' />
          <div className='_text_holder'>
            <p className='_stats_title'>YouTubers</p>
            <h4 className='_stats_numbers'>{getStats.YouTubers}</h4>
            <div className='_stats_holder'>
              <img src={Ali} alt='' className='_dashboard_creator_img' />
              <img src={Sara} alt='' className='_dashboard_creator_img' />
              <img src={Dave2d} alt='' className='_dashboard_creator_img' />
              <img src={Ruri} alt='' className='_dashboard_creator_img' />
              <img src={Unnamed} alt='' className='_dashboard_creator_img' />
            </div>
          </div>
        </div>
      </div>
      <LaTable howMany={5} no={98} />
    </>
  );
};

export default Index;
