import React, { useContext } from "react";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

import SponsorDetails from "./sponsorDetails";
import LaTable from "../Table/LaTable";

const Index = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { selectedSponsor, setSelectedSponsor } =
    contextValues as iGlobalValues;

  return (
    <main>
      {selectedSponsor ? (
        <SponsorDetails
          selectedSponsor={selectedSponsor}
          onBack={() => setSelectedSponsor(null)}
        />
      ) : (
        <LaTable howMany={7} yes={23} />
      )}
    </main>
  );
};

export default Index;
