import React, { useContext, useState } from "react";
import "../HomeBody/homeBody.css";

import DataTable from "../Table/dataTable";
import Pagination from "../pagination/pagination";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

interface iTable {
  howMany: number;
  yes?: number;
  no?: number;
}

const LaTable = ({ howMany, no, yes }: iTable): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(howMany);

  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { sponsors } = contextValues as iGlobalValues;

  //Get current page
  const indexofLastPage = currentPage * postPerPage;
  const indexOfFirstPage = indexofLastPage - postPerPage;
  const currentPages = sponsors?.slice(indexOfFirstPage, indexofLastPage);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <main className='_table_pagination_section'>
      <table
        className={
          no === 98 ? `la_table` : yes === 23 ? `la_table_sponsor` : ""
        }>
        <thead className='_table_head'>
          <tr>
            <th id='_sponsor_name'>Sponsor</th>
            <th>Industry</th>
            <th>Sponsorships</th>
            <th>Tags</th>
            <th>Popularity Index</th>
          </tr>
        </thead>
        <tbody className='_table_body'>
          {currentPages?.map((sponsor) => {
            return <DataTable sponsor={sponsor} key={sponsor.sponsor_id} />;
          })}
        </tbody>
      </table>
      <Pagination
        postPerPage={postPerPage}
        totalPages={sponsors?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </main>
  );
};

export default LaTable;
