import React, { useContext } from "react";
import "./notify.css";

import { RxCross2 } from "react-icons/rx";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import { iGlobalValues } from "../../Types/ContextGlobalVars";

const EmailUsageNotify = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { setCloseNotice } = contextValues as iGlobalValues;

  return (
    <div className='_notify_container'>
      <RxCross2
        onClick={() => setCloseNotice(false)}
        className='_notice_close_icon'
      />
      <div>
        <h2 className='_notice_title'>Important Notice </h2>
        <p className='_notice_text'>
          To ensure a seamless experience, please use the same email address
          that you used to create your account when making your payment on
          Stripe. This will help us link your payment to your account, giving
          you uninterrupted access to our services.
          <br />
          <br />
          If you've already made a payment, please make sure the email matches
          the one you used during payment.
        </p>
      </div>
    </div>
  );
};

export default EmailUsageNotify;
