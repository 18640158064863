import React, { useContext } from "react";
import "./PagesStyle.css";

import Pricing from "../Components/Pricing/index";
import Footer from "../Components/Footer/index";
import Logo from "../Assets/_sponsorwave.png";
import EmailUsageNotify from "../Components/GeneralUse/emailUsageNotify";
import { ContextGlobalVars } from "../Context/ContextGlobalVars";
import { iGlobalValues } from "../Types/ContextGlobalVars";

const ChosePlan = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { closeNotice } = contextValues as iGlobalValues;
  console.log(closeNotice);

  return (
    <div className='_chose_plan'>
      <div className='_chose_plan_container'>
        {closeNotice && (
          <>
            <EmailUsageNotify />
            <div className='_bg_drop'></div>
          </>
        )}
        <div className='_logo_container'>
          <img src={Logo} alt='Logo' className='_chose_plan_logo' />
        </div>
        <Pricing />
        <Footer />
      </div>
    </div>
  );
};

export default ChosePlan;
