import React, { useContext } from "react";
import "./dashboardHome.css";

import Logo from "../../Assets/_sponsorwave.png";
import UserAvatar from "../../Assets/favicon_package_v0.16/favicon-32x32.png";
import useLogout from "../../Hooks/useLogout";

import { FiChevronRight, FiChevronDown, FiSearch } from "react-icons/fi";
import { LuLogOut } from "react-icons/lu";

import HomeBody from "../HomeBody/index";
import Sponsors from "../Sponsors/index";
import YouTubers from "../YouTubers/index";
import SearchPlus from "../SearchPlus/index";
import MyFavorites from "../Favorites/index";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";

const Index = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { activeComponent, setActiveComponent } =
    contextValues as iGlobalValues;

  const heighLightEle = (idx: number) => {
    setActiveComponent(idx); // Update active component index
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 0:
        return <HomeBody />;
      case 1:
        return <Sponsors />;
      case 2:
        return <YouTubers />;
      case 3:
        return <SearchPlus />;
      case 4:
        return <MyFavorites />;
      default:
        return <HomeBody />; // Default to HomeBody if no valid index is found
    }
  };

  return (
    <main className='_dashboard'>
      <div className='_dashboard_sidebar'>
        <div>
          <img src={Logo} alt='dashboard_logo' className='_dashboard_logo' />
          <ul className='_dashboard_menu'>
            <li
              onClick={() => heighLightEle(0)}
              className={
                activeComponent === 0
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Home <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(1)}
              className={
                activeComponent === 1
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Sponsors <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(2)}
              className={
                activeComponent === 2
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              YouTubers <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(3)}
              className={
                activeComponent === 3
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              Search+ <FiChevronRight />
            </li>
            <li
              onClick={() => heighLightEle(4)}
              className={
                activeComponent === 4
                  ? "_dashboard_menu_ele _menu_li_active"
                  : "_dashboard_menu_ele"
              }>
              My Favorites <FiChevronRight />
            </li>
            <div className='_SMK_div'>
              <li className='_dashboard_menu_ele'>
                Smart Media Kit <FiChevronRight />
              </li>
              <span className='_coming_soon'>coming soon</span>
            </div>
          </ul>
        </div>

        <button className='_dashboard_logout_btn' onClick={useLogout()}>
          Logout <LuLogOut />
        </button>
      </div>
      <div className='_dashboard_content'>
        <div className='_dashboard_header'>
          <div className='_header_search'>
            <FiSearch />
            <input type='text' placeholder='creator, sponsor etc...' />
          </div>
          <div className='_dashboard_user'>
            <img src={UserAvatar} alt='user_image' className='_user_image' />
            <div className='_dashboard_user_text'>
              <h5>Username</h5>
              <p className='_dashboard_username'>
                Creator <FiChevronDown />
              </p>
            </div>
          </div>
        </div>
        <div className='_dashboard_body'>
          {renderActiveComponent()} {/* Render the active component */}
        </div>
      </div>
    </main>
  );
};

export default Index;
