import { onLogout } from "./../API/leApi";

import { useAuth } from "../Context/AuthProvider";
import { useNavigate } from "react-router-dom";

const useLogout = () => {
  const { dispatch } = useAuth();
  let navigate = useNavigate();

  const logout = async () => {
    try {
      const res = await onLogout();

      if (res?.status === 200) {
        dispatch({ type: "LOGOUT" });
        navigate("/", { replace: true });
      }
    } catch (err: any) {
      if (err.response) {
        alert(err?.response?.data);
      }

      if (err?.response?.status === 404) {
        alert("SORRY, WE COULDN'T FIND THAT user");
      } else if (err?.response?.status === 500) {
        alert("Some internal error");
      } else {
        alert("Something went wrong");
      }
    }
  };

  return logout;
};

export default useLogout;
