import React from "react";
import "./Features.css";

import le_dashboard from "../../Assets/SponsorWaveProduct.png";

const Index = () => {
  return (
    <main className='mainGrid'>
      <div className='_features_title'>
        <h3>YouTube Creators.</h3>
        <p>
          No more sitting back and waiting for sponsors to reach out. Our
          platform does the hard work, connecting you with brands that match
          your content.
          {/* Discover which brands are actively sponsoring YouTubers right now by
          checking your mates, helping you target those that are most likely to
          be interested in new collaborations. */}
        </p>
      </div>
      <div className='__features_div_container'>
        <img
          src={le_dashboard}
          alt='the_dashboard'
          className='le_dashboard_img'
        />
        <div className='_features_div'>
          <div>
            <h3>Analyze Your Competitors First.</h3>
            <p>
              Discover fellow YouTubers or competitors collaborating with
              sponsors that could be relevant to you.
            </p>
          </div>
          <div>
            <h3>Check out Their Brand Collaborations.</h3>
            <p>
              {/* Check out the companies they’ve partnered with, searching for new
              brands. */}
              Easily find sponsors in your niche that align with your content
              and audience preferences by tracking sponsor's collaborations with
              YouTubers.
            </p>
          </div>
          <div>
            <h3>Evaluate a Potential Sponsor's Fit.</h3>
            <p>
              By selecting a sponsor, you can find comprehensive information
              about their collaborations and the YouTubers they work with. Add
              it to your favorites for easy reference.
            </p>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Index;
