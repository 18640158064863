import React, { useEffect } from "react";

import PublicHeader from "../Components/TheHeader/index";
import Footer from "../Components/Footer/index";
// import CreatorHeader from "../Container/TheHeader/index";

import useRefreshToken from "../Hooks/useRefreshToken";
import { useLocation } from "react-router-dom";

const Skeleton = ({ children }: { children: React.ReactNode }) => {
  const { refresh } = useRefreshToken();
  let location = useLocation();

  useEffect(() => {
    refresh();
  }, [location.pathname]);

  return (
    <>
      <PublicHeader />
      {children}
      <Footer />
    </>
  );
};

export default Skeleton;
