import * as React from "react";
const SVGComponent = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={731.67004}
    height={350.61784}
    id='_contact_svg'
    viewBox='0 0 731.67004 550.61784'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    {...props}>
    <circle cx={317.68169} cy={132.62732} r={46.02133} fill='#ff6884' />
    <g>
      <path
        d='M0,334.13393c0,.66003,.53003,1.19,1.19006,1.19H730.48004c.65997,0,1.19-.52997,1.19-1.19,0-.65997-.53003-1.19-1.19-1.19H1.19006c-.66003,0-1.19006,.53003-1.19006,1.19Z'
        fill='#3f3d56'
      />
      <g>
        <polygon
          points='466.98463 81.60598 470.81118 130.55703 526.26809 107.39339 494.98463 57.60598 466.98463 81.60598'
          fill='#a0616a'
        />
        <circle cx={465.32321} cy={55.18079} r={41.33858} fill='#a0616a' />
        <polygon
          points='387.98463 440.60598 394.98463 503.39339 345.98463 496.60598 361.98463 438.60598 387.98463 440.60598'
          fill='#a0616a'
        />
        <polygon
          points='578.98463 449.60598 585.98463 512.39339 536.98463 505.60598 552.98463 447.60598 578.98463 449.60598'
          fill='#a0616a'
        />
        <path
          d='M462.48463,260.10598c-.66897,0-54.14584,2.68515-89.47714,4.46286-16.72275,.84141-29.45202,15.31527-28.15459,32.00884l12.63173,162.5283,36,1,.87795-131,71.12205,4-3-73Z'
          fill='#2f2e41'
        />
        <path
          d='M619.48463,259.10598s9,69,2,76c-7,7-226.5-5.5-226.5-5.5,0,0,48.15354-69.53704,56.82677-71.51852,8.67323-1.98148,146.67323-8.98148,146.67323-8.98148l21,10Z'
          fill='#2f2e41'
        />
        <path
          id='uuid-395a52e4-5c72-4314-a9bf-6720c6920e4c-427'
          d='M335.12666,172.23337c-8.35907-11.69074-9.10267-25.48009-1.66174-30.79863,7.44093-5.31854,20.24665-.15219,28.60713,11.54383,3.40375,4.62627,5.65012,10.00041,6.55111,15.67279l34.79215,49.9814-19.8001,13.70807-35.7745-48.83421c-5.07753-2.68845-9.43721-6.55406-12.71405-11.27326Z'
          fill='#a0616a'
        />
        <path
          id='uuid-2bcb0f29-4cb0-4836-b3e1-c42200070018-428'
          d='M465.67391,331.01678c-12.74718,6.63753-26.5046,5.44058-30.72743-2.67249-4.22283-8.11308,2.6878-20.06802,15.44041-26.70621,5.05777-2.72156,10.69376-4.19231,16.43644-4.28916l54.36547-27.44139,10.79681,21.52636-53.36733,28.57487c-3.37375,4.65048-7.81238,8.42516-12.94437,11.00803Z'
          fill='#a0616a'
        />
        <path
          d='M464.98463,112.60598l51-21,96,148s-67,15-90,18c-23,3-49-9-49-9l-8-136Z'
          fill='#e6e6e6'
        />
        <path
          d='M526.98463,137.60598l-18.5-57.70866,24,18.20866s68,45,68,64c0,19,21,77,21,77,0,0,23.5,19.5,15.5,37.5-8,18,10.5,15.5,12.5,28.5,2,13-28.5,30.5-28.5,30.5,0,0-7.5-73.5-31.5-73.5-24,0-62.5-124.5-62.5-124.5Z'
          fill='#3f3d56'
        />
        <path
          d='M468.56831,111.13035l-25.08368,9.97563s4,70,8,76,18,38,18,38v10.42913s-28,8.57087-27,13.57087c1,5,66,19,66,19,0,0-13-40-21-53-8-13-18.91632-113.97563-18.91632-113.97563Z'
          fill='#3f3d56'
        />
        <path
          d='M527.48463,97.10598s56-3,68,27c12,30,22,128,22,128l-122,66.37402-21-32.37402,82-64-29-125Z'
          fill='#3f3d56'
        />
        <path
          d='M452.48463,121.10598s-29-4-34,30-1.82283,38.5-1.82283,38.5l-8.17717,19.5-27-30-26,17s47,76,66,74c19-2,47-57,47-57l-16-92Z'
          fill='#3f3d56'
        />
        <path
          d='M597.32321,270.14478l-14.83858,209.96121-38.5-1.5s-8.5-198.5-8.5-201.5c0-3,4-20,29-21,25-1,32.83858,14.03879,32.83858,14.03879Z'
          fill='#2f2e41'
        />
        <path
          d='M541.48463,484.10598s20-6,23-2c3,4,20,6,20,6l5,49s-14,10-16,12-55,4-56-8c-1-12,14-27,14-27l10-30Z'
          fill='#2f2e41'
        />
        <path
          d='M394.48463,470.10598s6-5,8,9c2,14,9,37-1,40-10,3-110,4-110-5v-9l9-7,18.00394-2.869s34.99606-32.131,38.99606-32.131c4,0,17,13,17,13l20-6Z'
          fill='#2f2e41'
        />
        <path
          d='M505.98463,77.60598s-20-24-28-22-3,5-3,5l-20-22s-16-6-31,13c0,0-9-16,0-25,9-9,12-8,14-13,2-5,16-9,16-9,0,0-.80315-7.19685,3.59843-3.59843s15.3937,3.59843,15.3937,3.59843c0,0,.06299-4,4.53543,0s9.47244,2,9.47244,2c0,0,0,6.92126,3.5,6.96063,3.5,.03937,9.5-4.96063,10.5-.96063s8,6,9,18-4,47-4,47Z'
          fill='#2f2e41'
        />
      </g>
    </g>
    <g>
      <rect
        x={104.73115}
        y={107.21421}
        width={197.88719}
        height={35.06862}
        rx={17.53429}
        ry={17.53429}
        fill='#3f3d56'
      />
      <rect
        x={125.39658}
        y={30.60598}
        width={159.89619}
        height={175.34308}
        fill='#fff'
      />
      <path
        d='M286.29322,206.94876H124.39674V29.60598h161.89648V206.94876Zm-159.89648-2h157.89648V31.60598H126.39674V204.94876Z'
        fill='#3f3d56'
      />
      <rect
        x={91.78916}
        y={132.68071}
        width={227.11104}
        height={46.75816}
        rx={23.37905}
        ry={23.37905}
        fill='#3f3d56'
      />
      <ellipse
        cx={332.25967}
        cy={157.52098}
        rx={4.17484}
        ry={13.9857}
        fill='#3f3d56'
      />
      <ellipse
        cx={73.41988}
        cy={156.26853}
        rx={5.0098}
        ry={13.56821}
        fill='#3f3d56'
      />
      <rect
        x={74.67234}
        y={154.38985}
        width={28.80636}
        height={2.92238}
        fill='#3f3d56'
      />
      <rect
        x={306.37569}
        y={155.6423}
        width={23.37908}
        height={4.17484}
        fill='#3f3d56'
      />
      <path
        d='M302.33424,337.95657H129.43873c-13.66895,0-24.79004-11.12109-24.79004-24.79004v-123.84082c0-13.66895,11.12109-24.79004,24.79004-24.79004h172.89551c13.66895,0,24.78906,11.12109,24.78906,24.79004v123.84082c0,13.66895-11.12012,24.79004-24.78906,24.79004Z'
        fill='#3f3d56'
      />
      <circle cx={123.51791} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={131.03261} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={136.4599} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={146.06202} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={161.92639} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={177.79077} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={193.65514} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={209.51951} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={225.38389} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={241.24826} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={257.11264} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={272.97701} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={288.84139} cy={308.02379} r={5.42729} fill='#6c63ff' />
      <circle cx={152.61651} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={168.77312} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={184.92974} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={201.08635} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={217.24296} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={233.39957} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={249.55619} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={265.7128} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={281.86941} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={298.02602} cy={291.74193} r={5.42729} fill='#6c63ff' />
      <circle cx={147.31447} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={163.59633} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={179.87818} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={196.16004} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={212.4419} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={228.72376} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={245.00561} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={261.28747} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={277.56933} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={293.85119} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={310.13305} cy={275.87756} r={5.42729} fill='#6c63ff' />
      <circle cx={139.79977} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={156.08162} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={172.36348} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={188.64534} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={204.9272} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={221.20905} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={237.49091} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={253.77277} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={270.05463} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={286.33648} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={302.61834} cy={258.34325} r={5.42729} fill='#6c63ff' />
      <circle cx={128.11023} cy={187.78853} r={21.29166} fill='#6c63ff' />
      <circle cx={300.94841} cy={187.78853} r={21.29166} fill='#6c63ff' />
      <path
        d='M180.52231,179.43886h65.70058c2.9447,0,5.62784,1.69069,6.89874,4.34702l17.73104,37.05981c2.42863,5.0761-1.27158,10.94834-6.89874,10.94834h-96.13887c-5.24759,0-8.93627-5.16429-7.23423-10.12818l12.70725-37.05981c1.05987-3.09102,3.96655-5.16717,7.23423-5.16717Z'
        fill='#6c63ff'
      />
    </g>
    <rect
      x={155.56764}
      y={56.60598}
      width={96.41699}
      height={2}
      fill='#3f3d56'
    />
    <rect
      x={155.56764}
      y={74.60598}
      width={96.41699}
      height={2}
      fill='#3f3d56'
    />
    <rect
      x={155.56764}
      y={92.60598}
      width={96.41699}
      height={2}
      fill='#3f3d56'
    />
  </svg>
);
export default SVGComponent;
