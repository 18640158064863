import React from "react";

import Home from "../Container/Home/index";

const Dashboard = () => {
  return (
    <div>
      <Home />
    </div>
  );
};

export default Dashboard;
