import React, { useState } from "react";
import "./searchPlus.css";

const Index = () => {
  const [searchTerms, setSearchTerms] = useState({});

  return (
    <div>
      <h1>Search Plus</h1>
      <div>
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
        <input type='text' />
      </div>
    </div>
  );
};

export default Index;
