import React, { useState } from "react";
import { onStoreChannel } from "../API/leApi";
import "./PagesStyle.css";

// import Loader from "../Loader";

const StoreChannel = () => {
  const [channelId, setChannelId] = useState<string>("");
  const [laMessage, setLaMessage] = useState<string>("");
  // const [isLoading, setIsLoading] = useState(false);

  const onSubmitChannelId = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setIsLoading(true);

    try {
      let res = await onStoreChannel(channelId);

      if (res.status === 200) {
        setLaMessage(res.data?.message); // Display server response message
        setChannelId(""); // Clear the channelId field
        clearMessageAfterDelay();
      }
    } catch (error: any) {
      console.log(error);
      if (error.response?.status === 409) {
        setLaMessage(
          `This channel is already exist on the database, thanks for trying :)\n Let's carry on!`
        );
        setChannelId(""); // Clear the channelId field even in case of error
        clearMessageAfterDelay();
      } else {
        setLaMessage(error?.response?.data?.message); // Display error message from server
        clearMessageAfterDelay();
      }
    }
  };

  const clearMessageAfterDelay = () => {
    setTimeout(() => {
      setLaMessage(""); // Clear the laMessage field after 15 seconds
    }, 5000);
  };

  return (
    <div className='form_div'>
      {/* {isLoading && <Loader />} */}
      <form onSubmit={onSubmitChannelId}>
        <input
          type='text'
          placeholder='channel id'
          value={channelId}
          onChange={(e) => setChannelId(e?.target.value)}
        />
        <button>Send The Channel</button>
      </form>
      {laMessage && <p>{laMessage}</p>}
    </div>
  );
};

export default StoreChannel;
