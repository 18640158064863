import React, { useState } from "react";
import "./pricing.css";

// import Fatures from "./features";
import BrandFeaturs from "./BrandFeaturs";
import { onCheckingOut } from "../../API/leApi";
import Loader from "../../utils/Loader";

const Index = () => {
  const [active, setActive] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [whoIsThis, setWhoIsThis] = useState("Yearly");

  const handleActive = (e: any) => {
    if (e.target.innerText === "Yearly") {
      setActive(true);
      setWhoIsThis("Yearly");
    } else {
      setActive(false);
      setWhoIsThis("Monthly");
    }
  };

  const handleCheckOut = async (price: string) => {
    setIsLoading(true);

    try {
      let res = await onCheckingOut(price);
      if (res.status === 200) window.location = res.data.url;
    } catch (error) {
      alert("Something went wrong please try again!");
    } finally {
      setIsLoading(false);
    }
  };

  let path = window.location.pathname.split("/")[1];
  if (isLoading && path !== "chose-plan") {
    return <Loader />;
  }

  return (
    <main className='pricing' id='pricing_scroll'>
      <div className='_pricing_title'>
        <h1>Pricing</h1>
        <p>
          Find the right sponsors for your content, boost your revenue, and
          focus on making amazing content for your audience.
        </p>
        <div className='pricing_btns_div'>
          <button
            onClick={handleActive}
            className={active ? "pice_btns" : "pice_btns pice_btns_active"}>
            Monthly
          </button>
          <button
            onClick={handleActive}
            className={active ? "pice_btns pice_btns_active" : "pice_btns"}>
            Yearly
          </button>
        </div>
      </div>
      {whoIsThis === "Monthly" ? (
        <div className='_princing_divs'>
          <div className='pricing_item2'>
            <div className='_p_sub_title'>
              <h4>Power Plan</h4>
              <p>
                Unlock a network of sponsors waiting to collaborate with
                creators like you.
              </p>
            </div>
            <h2 className='_price'>
              $79.99 <span>/month</span>
            </h2>
            <BrandFeaturs />
            {/* <button>Get Started</button> */}
            <button onClick={() => handleCheckOut("79.99")}>Get Started</button>
          </div>
        </div>
      ) : (
        <div className='_princing_divs'>
          <div className='pricing_item2'>
            <div className='_p_sub_title'>
              <h4>Power Plan</h4>
              <p>
                Unlock a network of sponsors waiting to collaborate with
                creators like you.
              </p>
            </div>
            <div className='_price_div'>
              <p>
                <span id='original_price'>$79.99</span>{" "}
                <span className='dis_price'> save 20%</span>
                <br />
              </p>
              <h2 className='_price'>
                $64.99 <span>/year</span>
              </h2>
              <span>Billed annually: $779.88</span>
            </div>
            <BrandFeaturs />
            {/* <button>Get Started</button> */}
            <button onClick={() => handleCheckOut("64.99")}>Get Started</button>
          </div>
        </div>
      )}
    </main>
  );
};

export default Index;
