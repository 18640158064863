import React, { useContext, useEffect } from "react";
import "./Youtubers.css";
import "../HomeBody/homeBody.css";

import { iChannels_info } from "../../Types/creatorStuffTypes";
import { RiHeartFill, RiHeartLine } from "react-icons/ri";
import { MdOutlinePeopleAlt } from "react-icons/md";

import { iGlobalValues } from "../../Types/ContextGlobalVars";
import { ContextGlobalVars } from "../../Context/ContextGlobalVars";
import {
  onGetUserFavoriteCreators,
  onAddToFavoritesOrRemoveCreators,
} from "../../API/leApi";

interface iCreator {
  creator: iChannels_info;
  onClick: () => void;
}

const ChannelsTable = ({ creator, onClick }: iCreator) => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { userFavoriteCreators, setUserFavoriteCreators } =
    contextValues as iGlobalValues;

  const addToFavorite = async (channel_id: string) => {
    try {
      const res = await onAddToFavoritesOrRemoveCreators(channel_id);
      if (res.status === 200) {
        await fetchUserFavorites();
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      let res = await onGetUserFavoriteCreators();

      if (res.status === 200) {
        let { channel_ids, user_id } = res.data.user_favorites_creators;
        setUserFavoriteCreators({ channel_ids, user_id });
      }
    } catch (error: any) {
      alert(error.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchUserFavorites();
  }, []);

  return (
    <tr>
      <td>
        <div className='channels_first_row' onClick={onClick}>
          <img src={creator.channel_photo} alt='' className='_sponsors_logos' />
          <div className='channel_names'>
            <li> {creator.channel_name}</li>
            <p className='_channel_username'> {creator.channel_username}</p>
          </div>
        </div>
      </td>
      <td className='_tags_row'>
        <div className='_channel_tags'>
          <p>Tech</p>
          <p>Travel</p>
          <p>Finance</p>
        </div>
      </td>
      <td>
        <div className='_channel_subs'>
          <MdOutlinePeopleAlt />
          {Number(creator.subscribers).toLocaleString()}
        </div>
      </td>
      <td className='_dashboard_pIdx'>{creator.sponsored_video_count}</td>
      <td>
        {userFavoriteCreators?.channel_ids?.some(
          (fav: string) => fav === creator.channel_id
        ) ? (
          <RiHeartFill
            className='_add_to_favorite'
            onClick={() => addToFavorite(creator.channel_id)}
          />
        ) : (
          <RiHeartLine
            className='_add_to_favorite'
            onClick={() => addToFavorite(creator.channel_id)}
          />
        )}
      </td>
    </tr>
  );
};

export default ChannelsTable;
