import React, { useContext, useEffect } from "react";
import { ContextGlobalVars } from "../Context/ContextGlobalVars";
import { useAuth } from "../Context/AuthProvider";
import { iGlobalValues } from "../Types/ContextGlobalVars";
import "../App.css";

import Skeleton from "../utils/skeleton";
import { useNavigate } from "react-router-dom";

const CheckEmail = () => {
  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  const { reverificationSuccess } = contextValues as iGlobalValues;
  const { verificationEmail } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!verificationEmail) navigate("/signUp", { replace: true });
  }, [verificationEmail]);

  return (
    <div className='checkYourEmailPage'>
      <Skeleton>
        <div className='msgContainer'>
          <div>
            <h1>Your Email 😊</h1>
            <p>{reverificationSuccess}</p>
          </div>
        </div>
      </Skeleton>
    </div>
  );
};

export default CheckEmail;
