import React from "react";
import "./PagesStyle.css";

import NotFoundSvg from "../Components/Errors/_404";
import Skeleton from "../utils/skeleton";

const NotFound = () => {
  return (
    <Skeleton>
      <div className='_404_page_container'>
        <div className='_404_svg'>
          <NotFoundSvg />
        </div>
        <h2 className='_not_found_title'>Not Found!</h2>
      </div>
    </Skeleton>
  );
};

export default NotFound;
