import { useEffect, useState } from "react";

import Loader from "./Loader";
import { useAuth } from "../Context/AuthProvider";
import { onRefreshToken } from "../API/leApi";
import { useLocation, Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const [isLoading, setIsLoading] = useState(true); // This is for the loader
  const { state, dispatch } = useAuth();

  let location = useLocation();
  let path = location.pathname;

  useEffect(() => {
    (async () => {
      try {
        const response = await onRefreshToken();

        if (response.status === 200) {
          dispatch({
            type: "LOGIN",
            payload: {
              accessToken: response.data.accessToken,
              user_id: response.data.user.user_id,
              customer_id: response.data.user.customer_id,
            },
          });
        }
        setIsLoading(false);
      } catch (error: any) {
        if (error.response?.status !== 403) {
          setIsLoading(false);
        } else {
          dispatch({ type: "LOGOUT" });
          setIsLoading(false);
        }
      }
    })();
  }, [path]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      {state.accessToken && state.isAuthenticated ? (
        <Outlet />
      ) : (
        <Navigate to='/' />
      )}
    </>
  );
};

export default ProtectedRoute;
