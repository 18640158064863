import React from "react";
import "../PagesStyle.css";

import { RiTwitterXLine } from "react-icons/ri";
import { GrLinkedinOption } from "react-icons/gr";
import Skeleton from "../../utils/skeleton";
import ContactSVG from "./ContactSVG";

const contactUs = () => {
  return (
    <Skeleton>
      <div className='contact_container'>
        <ContactSVG />
        <h3>Contact Us...</h3>
        <p>
          If you have any questions, don't hesitate to get in touch with us. You
          can email us at <span>info.sponsorwave@gmail.com</span> or you contact
          Sabli Junior "The founder" directly on X or Linkedin and he will get
          back to you in 48 hours.
        </p>
        <div className='contact_socials_container'>
          <a
            href='https://twitter.com/sablijr'
            target='_blank'
            rel='noopener noreferrer'>
            <RiTwitterXLine />
          </a>
          <a
            href='https://www.linkedin.com/in/sablijr/'
            target='_blank'
            rel='noopener noreferrer'>
            <GrLinkedinOption />
          </a>
        </div>
      </div>
    </Skeleton>
  );
};

export default contactUs;
