import React, {
  createContext,
  useContext,
  useReducer,
  useState,
  useEffect,
} from "react";
import { iRegistrationInfo } from "../Types/creatorStuffTypes";
import { onRefreshToken } from "../API/leApi";

type AuthContextType = {
  state: iState;
  dispatch: React.Dispatch<iAction>;
  verificationEmail: string;
  setVerificationEmail: React.Dispatch<React.SetStateAction<string>>;
  registerValues: iRegistrationInfo;
  setRegisterValues: React.Dispatch<React.SetStateAction<iRegistrationInfo>>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type iState = {
  isAuthenticated: boolean;
  accessToken: string | null;
  user_id?: string | null;
  customer_id?: string | null;
};

type iAction = {
  type: string;
  payload?: {
    accessToken?: string;
    user_id?: string;
    customer_id?: string;
  };
};

const initialState = {
  isAuthenticated: false,
  accessToken: null,
  user_id: null,
  customer_id: null,
};

const authReducer = (state: iState, action: iAction) => {
  switch (action.type) {
    case "LOGIN":
      return {
        isAuthenticated: true,
        accessToken: action.payload?.accessToken as string,
        user_id: action.payload?.user_id,
        customer_id: action.payload?.customer_id,
      };
    case "LOGOUT":
      return {
        isAuthenticated: false,
        accessToken: null,
      };
    default:
      return state;
  }
};

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [verificationEmail, setVerificationEmail] = useState("");
  const [registerValues, setRegisterValues] = useState<iRegistrationInfo>({
    user_name: "",
    email: "",
    password: "",
    customer_id: "",
  });

  let path_name = window.location.pathname;
  useEffect(() => {
    (async () => {
      try {
        const response = await onRefreshToken();

        if (response.status === 200) {
          dispatch({
            type: "LOGIN",
            payload: {
              accessToken: response.data.accessToken,
              user_id: response.data.user.user_id,
              customer_id: response.data.user.customer_id,
            },
          });
        }
      } catch (error: any) {
        if (error.response?.status !== 403) {
        } else {
          dispatch({ type: "LOGOUT" });
        }
      }
    })();
  }, [path_name]);

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        verificationEmail,
        setVerificationEmail,
        registerValues,
        setRegisterValues,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
