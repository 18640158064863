import React from "react";
import "./App.css";

//RoutersFile
import LaRoutes from "./Routes";
import { AuthProvider } from "./Context/AuthProvider";
import { GlobalValuesProvider } from "./Context/ContextGlobalVars";

import ReactGA from "react-ga4";
ReactGA.initialize("G-BVRQT2SHLP");
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname,
});

const App = () => {
  //  const contextValues = useContext<Partial<iGlobalValues>>(ContextGlobalVars);
  // const { GlobalValuesProvider } = contextValues as iGlobalValues;

  return (
    <div className='App'>
      <AuthProvider>
        <GlobalValuesProvider>
          <LaRoutes />
        </GlobalValuesProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
